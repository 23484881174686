import ProductFlowRoutes from "@surecomp/product-flow/routes.const";
import { Routes as ReportsRoutes } from "@surecomp/reports-ui/constants/route";
import { isEnableNewReport } from "./helpers/featureFlag";

export const URL_LANG_KEY = "lang";

const Routes = {
	APP_ABOUT_JSON: "/about",
	BRANDING: "/branding",
	USER_PROFILE: "/userProfile",
	LOGIN: "/login",
	LOGOUT: "/logout",
	LOGOUT_SSO: "/logout/sso",
	CHANGE_ORGANIZATION: "login/update/organization",
	BASE: "/",
	NOT_FOUND: "/404",
	NOTIFICATION_SETTINGS: "/notificationSettings",
	TASK_MANAGEMENT: "/tasks",
};

export default Routes;

const ReportsRoutesFiltered = isEnableNewReport("router") ? [
	ReportsRoutes.DealReports,
	ReportsRoutes.Deals,
	"/new-reports/selection",
] : [];

export const FlowRoutes = Object.values(ProductFlowRoutes).filter((path) => !([
	ProductFlowRoutes.HOME,
	ProductFlowRoutes.PRODUCT_STATE_V2,
	ProductFlowRoutes.PRODUCT_UNDERTAKING_TYPE_V2,
	ProductFlowRoutes.PRODUCT_STATE_MODE_V2,
	ProductFlowRoutes.PRODUCT_STATE_DISCUSSION_NOTIFICATION_V2,
	ProductFlowRoutes.NOT_FOUND,
] as string[]).includes(path));

export const ValidRoutes = [
	Routes.BASE,
	Routes.TASK_MANAGEMENT,
	Routes.BRANDING,
	Routes.USER_PROFILE,
	Routes.NOTIFICATION_SETTINGS,
	...FlowRoutes,
	...ReportsRoutesFiltered,
];
