import DataService from "@surecomp/login/services/data.service";

export function isAllowSubs() {
	return process.env.REACT_APP_ALLOW_SUBS === "true" || window?.env?.ALLOW_SUBS === true;
}

export function isAllowOgIg() {
	return process.env.REACT_APP_ALLOW_OG_IG === "true" || window?.env?.ALLOW_OG_IG === true;
}

export function isSuperUser(token: string) {
	const ds = new DataService();
	return ds.checkIfSuperUser(token);
}

export function isAllowTaskManagement() {
	return process.env.REACT_APP_ALLOW_TASK_MANAGEMENT === "true" || window?.env?.ALLOW_TASK_MANAGEMENT === true;
}

export function isAllowBrowserNotification() {
	return process.env.REACT_APP_ALLOW_BROWSER_NOTIFICATION === "true" || window?.env?.ALLOW_BROWSER_NOTIFICATION === true;
}

export function isAllowILC() {
	return window?.env?.ALLOW_ILC === true;
}

export function isAllowInvoice() {
	return process.env.REACT_APP_ALLOW_INVOICE === "true" || window?.env?.ALLOW_INVOICE === true;
}

export function isAllowPairProfiles() {
	return process.env.REACT_APP_PAIR_PROFILES === "true" || window?.env?.ALLOW_PAIR_PROFILES === true;
}

export function isAllowThemeSettings() {
	return process.env.REACT_APP_THEME_SETTINGS === "true" || window?.env?.THEME_SETTINGS === true;
}

export function isEnableNewReport(feat: string) {
	const strFlags: string | undefined = process.env.REACT_APP_ENABLE_NEW_REPORT || window?.env?.ENABLE_NEW_REPORT;
	if (typeof strFlags !== "string") {
		return false;
	}
	const features = strFlags.toLowerCase().split(",");
	const theFeat = feat.toLowerCase().trim();
	const found = features.findIndex((i) => i.trim() === theFeat);
	return found !== -1;
}

export function isDisableOldReport() {
	return process.env.REACT_APP_DISABLE_OLD_REPORT === "true" || window?.env?.DISABLE_OLD_REPORT === true;
}

export function isEnableNewMigrationRoutes() {
	return process.env.REACT_APP_ENABLE_NEW_MIGRATION_ROUTES === "true" || window?.env?.ENABLE_NEW_MIGRATION_ROUTES === true;
}

export function isEnableIncomingStandbyLc() {
	return process.env.ENABLE_INCOMING_STANDBY_LC === "true" || window?.env?.ENABLE_INCOMING_STANDBY_LC === true;
}
